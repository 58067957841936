import React from "react";

import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <hr></hr>
      <p style={{ paddingBottom: "5px" }}>2023 VisitHop</p>
      <p>info@visithop.com</p>
    </div>
  );
};

export default Footer;
